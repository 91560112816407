


























import Vue from "vue";
import AppPage from "@/components/layout/page/AppPage.vue";
import WebinarPromotionCard from "@/components/webinars/WebinarPromotionCard.vue";
import {
  ApiException,
  UserCertificateResponse,
  WebinarsSummaryPublicResponse,
  WebinarsUserClient,
} from "@/clients/clients";
import { mapActions, mapGetters } from "vuex";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "UserCertificatesView",
  metaInfo: {
    title: "My certificates",
  },
  components: {
    AppPage,
    WebinarPromotionCard,
  },
  data: () => ({
    errorMessage: "",
    loading: false,
    collection: [] as WebinarsSummaryPublicResponse[],
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
  },
  async created() {
    this.loading = true;
    this.errorMessage = "";
    try {
      await this.getUser();
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const webinarsUserClient = this.$httpClientFactory(
        WebinarsUserClient,
        token.accessToken
      );
      const webinars = this.user.certificates
        ?.filter((p: UserCertificateResponse) => p.module?.id?.length)
        .map((p: UserCertificateResponse) => p.module?.id);
      const response = await webinarsUserClient.batch(webinars);
      this.collection = response.webinars ?? [];
    } catch (e) {
      this.errorMessage =
        (e as ApiException).message ?? "Unknown error occurred";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("UserStore", ["getUser"]),
  }
});
